<template>
    <div class="add-credit-card">
        <stripe-payment-form :config="config" 
         @setupConfirmed="updateBilling($event)" btnText="Update Payment Method" />
    </div>
</template>


<script>
import StripePaymentForm from "./StripePaymentForm.vue"

export default {
    components:{
        StripePaymentForm 
    },
    props:{
        config:{
            type: Object,
            required: true
        }
    },
    methods:{
        updateBilling(setup){
            let formData = new FormData();
            formData.append('setup_id', setup.id);
            formData.append('type', "payment-method");
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post('/subscriptions/update', formData)
                .then(response => {
                this.$store.dispatch("changeLoaderValue")
                if(response.data.success){
                    this.$store.commit("SET_SUBSCRIPTION", response.data.data)
                    this.$emit("closeMe")
                }
            })
        }
    },
}
</script>